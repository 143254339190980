import { FC, useState, ReactNode } from "react";

import { Text, Box, Column, useStyleConfig } from "@hightouchio/ui";

import { Link } from "src/router";

import { InviteFormModal } from "src/components/modals/invite-modal";
import { Sidebar } from "./sidebar";
import { newPylonMessage } from "src/lib/pylon";
import { useUser } from "src/contexts/user-context";

type SidebarFormProps = {
  buttons?: ReactNode;
  name: string;
  docsUrl?: string;
  hideInviteTeammate?: boolean;
  hideSendMessage?: boolean;
  invite?: string;
  message?: string;
  top?: string;
};

export const SidebarForm: FC<Readonly<SidebarFormProps>> = (props) => {
  return (
    <Sidebar top={props.top}>
      <SidebarFormContent {...props} />
    </Sidebar>
  );
};

const SidebarFormContent: FC<Readonly<Omit<SidebarFormProps, "top">>> = ({
  buttons,
  ...props
}) => {
  return (
    <>
      {buttons && (
        <Column
          gap={3}
          mb={10}
          align="stretch"
          sx={{ span: { width: "100% !important" }, button: { width: "100%" } }}
        >
          {buttons}
        </Column>
      )}
      <Docs {...props} />
    </>
  );
};

type DocsProps = Omit<SidebarFormProps, "top" | "buttons">;

const Docs: FC<Readonly<DocsProps>> = ({
  name,
  docsUrl,
  hideSendMessage,
  hideInviteTeammate,
  invite,
  message,
}) => {
  const { user } = useUser();
  const [openInvite, setOpenInvite] = useState(false);
  const styles = useStyleConfig("Link");

  let correctedDocsUrl = docsUrl;

  // Some docs pages have different URLs than the slugs stored in Sanity, see https://github.com/hightouchio/docs/blob/d4559b38e5014847c1a0bfdad037fc7cfe9a7019/next.config.mjs#L14
  const docsURLOverrides = {
    "hightouch-personalization-api": "personalization-api",
    "webhook-v2": "http-request",
  };

  // Replace the incorrect URL with the overwritten one
  const incorrectSlugs = Object.keys(docsURLOverrides);
  incorrectSlugs.forEach((slug) => {
    if (docsUrl?.includes(slug)) {
      correctedDocsUrl = docsUrl.replace(slug, docsURLOverrides[slug]);
    }
  });

  // Another common overwrite is to remove the "-destination" or "-source" from the slug in Sanity
  let docsLink: string | null;
  if (correctedDocsUrl) {
    correctedDocsUrl = correctedDocsUrl.replace("-destination", "");
    correctedDocsUrl = correctedDocsUrl.replace("-source", "");

    docsLink = correctedDocsUrl.startsWith("http")
      ? correctedDocsUrl
      : `${import.meta.env.VITE_DOCS_URL}/${correctedDocsUrl}`;
  } else {
    docsLink = null;
  }

  return (
    <Column gap={8}>
      {docsLink && (
        <Block
          content={
            <>
              Not sure where to start? Check out the{" "}
              <Link href={docsLink} isExternal>
                docs for {name}
              </Link>{" "}
              for step-by-step instructions.
            </>
          }
          title="Read our docs"
        />
      )}
      <Block
        content={
          <>
            {invite || "If you’re missing credentials or connection info"},{" "}
            <Box
              __css={styles}
              display="inline-block"
              onClick={() => setOpenInvite(true)}
            >
              invite a teammate
            </Box>{" "}
            to join you in this Hightouch workspace.
          </>
        }
        hide={hideInviteTeammate || user?.permissions_v2_enabled}
        title="Invite a teammate"
      />
      <Block
        content={
          <>
            We’re here to help!{" "}
            <Box
              __css={styles}
              display="inline-block"
              onClick={() =>
                newPylonMessage(message || "I could use some help")
              }
            >
              Chat with us
            </Box>{" "}
            if you feel stuck or have any questions.
          </>
        }
        hide={hideSendMessage}
        title="Contact support"
      />
      <InviteFormModal
        close={() => setOpenInvite(false)}
        name="Hightouch"
        open={openInvite}
      />
    </Column>
  );
};

const Block: FC<
  Readonly<{ title: string; content: ReactNode; hide?: boolean }>
> = ({ title, content, hide }) => {
  if (hide) return null;

  return (
    <Box>
      <Text fontWeight="medium" color="text.secondary">
        {title}
      </Text>
      <Box pt={2} mt={2} borderTop="1px solid" borderColor="base.border">
        {content}
      </Box>
    </Box>
  );
};
