import FuzzySet from "fuzzyset";

import { ColumnOption } from "src/formkit/components/formkit-context";
import { ColumnReference, isColumnReference } from "src/types/visual";
import { flattenOptions, Option } from "src/ui/select";
import { StandardFieldType } from "src/utils/destinations";

type BaseField = {
  label: string;
  value: string | ColumnReference;
  object?: { label: string; value: string };
  required?: boolean;
};

type StandardField = BaseField & {
  type?: Exclude<StandardFieldType, StandardFieldType.REFERENCE>;
  extendedType?: { type: string; semanticColumnType?: string };
};
type ReferenceField = BaseField & {
  type: StandardFieldType.REFERENCE;
  referenceObjects: { label: string; value: string }[];
};

type Field = StandardField | ReferenceField;

// Meaning of life, it seems
const MIN_SCORE = 0.42;

// Simple helper for an often reused closure
const valueOrLabel = (f: { label: string; value: ColumnReference | string }) =>
  isColumnReference(f.value) ? f.label : f.value;

/**
 * Matches similar columns and fields to create array of mappings.
 * Iterates through fields comparing to all columns.
 * @param columns
 * @param fields Fields that you want matched (generally unmatched options)
 * @returns Array of Mappings
 */
export const automap = (
  columns: ColumnOption[],
  fields: Field[],
): Mapping[] => {
  // Filter out boosted columns and represent the columns as Options, flattening nested structure as needed
  const options: Option[] = flattenOptions(
    columns.filter((col) => col.label !== "boosted"),
  );
  // Create a fuzzy searcher over these values
  const matcher = FuzzySet(options.map(valueOrLabel));
  const matched: Mapping[] = [];

  for (const field of fields) {
    if (isColumnReference(field.value)) {
      // Unable to use matcher here; we are unable to retrieve value as an object as it was mapped.
      matched.push(suggest(field, options));
    } else {
      const [bestMatch] = [
        ...matcher.get(field.value, [], MIN_SCORE),
        ...matcher.get(field.label, [], MIN_SCORE),
      ].sort((a, b) => b[0] - a[0]);
      if (!bestMatch) {
        continue;
      }
      const column = bestMatch[1];
      matched.push(getMappingFromField(column, field));
    }
  }

  return matched;
};

/**
 * Matches a single column to a list of fields
 * Used for when column contains a reference
 * @param column
 * @param fields
 * @returns Mapping
 */
export const suggest = (
  column: { label: string; value: ColumnReference | string },
  fields: (Field | Option)[] = [],
): Mapping => {
  const matcher = FuzzySet(fields.map(valueOrLabel));
  const labelResults = matcher.get(column.label, [], MIN_SCORE);
  const valueResults = isColumnReference(column.value)
    ? []
    : matcher.get(column.value, [], MIN_SCORE);
  const [bestMatch] = [...labelResults, ...valueResults].sort(
    (a, b) => b[0] - a[0],
  );

  if (!bestMatch) {
    return {
      from: column.value,
      to: undefined,
      object: undefined,
      type: "standard",
    };
  }

  const field = bestMatch[1];
  return getMappingFromField(
    column.value,
    fields.find((f) => field === valueOrLabel(f)),
  );
};

interface Mapping {
  lookup?: {
    by: null;
    byType: null;
    from: ColumnReference | string;
    object: unknown;
  };
  from?: ColumnReference | string;
  to: string | undefined;
  object: string | undefined;
  type: "reference" | "standard";
}

export const getMappingFromField = (
  columnValue: ColumnReference | string,
  field: Field | Option | undefined,
): Mapping => {
  if (field?.type === StandardFieldType.REFERENCE) {
    return {
      lookup: {
        by: null,
        byType: null,
        from: columnValue,
        object:
          ("referenceObjects" in field &&
            field?.referenceObjects?.[0]?.value) ??
          field.value,
      },
      to: isColumnReference(field.value) ? field.label : field.value,
      object: field?.object?.value,
      type: "reference",
    };
  } else {
    const toValue = !field
      ? undefined
      : isColumnReference(field.value)
        ? field.label
        : field.value;
    return {
      from: columnValue,
      to: toValue,
      object: field?.object?.value,
      type: "standard",
    };
  }
};
